<template>
  <div :class="windowClass">
    <v-container>
      <v-row no-gutters>
        <v-col class="pa-2 mt-8 text-center"><img :src="$store.state.systemlogo" width="120"/></v-col>
        
      </v-row>
      <v-row>
        <v-col cols="12"  sm="6">
          <v-row >
            <v-col cols="12" sm="6">
                      <v-card class="text-center pa-4">
                            <span class="text-h5 font-weight-bold">{{Adults.length}}</span>
                            <div><v-icon left color="success">mdi-family-tree</v-icon>Adults</div>
                      </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                      <v-card class="text-center pa-4">
                            <span class="text-h5 font-weight-bold">{{Seniors.length}}</span>
                            <div><v-icon left color="success">mdi-account-credit-card-outline</v-icon>SENIOR CITIZEN</div>
                      </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                      <v-card class="text-center pa-4">
                            <span class="text-h5 font-weight-bold">{{Employed.length}}</span>
                            <div><v-icon left color="success">mdi-account-hard-hat-outline</v-icon>EMPLOYED</div>
                      </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                      <v-card class="text-center pa-4">
                        <span class="text-h5 font-weight-bold">{{SKs.length}}</span>
                            <div><v-icon left color="success">mdi-family-tree</v-icon>SK MEMBERS</div>
                      </v-card>
            </v-col>
          </v-row>

        </v-col>
        <v-col cols="12"  sm="6">
          <v-card  elevation="1">
            <v-toolbar dense elevation="1"><v-icon left color="info">mdi-cake-variant</v-icon>This Month Birthdays ({{birthdays.length}})</v-toolbar>
            <v-card-text class="pa-1">
              <div id="bday">
               <v-list dense>
                <v-list-item v-for="(item,index) in birthdays" :key="index" >
                  <v-list-item-title :class="isToday(item)?'text-info':''"> {{item.surname}}, {{item.first_name}}</v-list-item-title>
                  <v-list-item-subtitle>{{$moment(item.birth_date).format("MMM, DD")}}</v-list-item-subtitle>
                  <v-list-item-icon><v-icon :color="isToday(item)?'info':''">mdi-cake-variant</v-icon></v-list-item-icon>
                </v-list-item>
               </v-list>  
              </div>  
            </v-card-text>
          </v-card>
         
        
         
        </v-col>

      </v-row>
    
     
     </v-container>
     <va-census-form :show="form" @DialogEvent="formEv"/>
   </div>
 </template>
 <script>
 import {
   mapMutations
 } from 'vuex'
   export default {
     data: () => ({
         form: false,
         search: '',
         items:[],
         birthdays:[],
         headers: [
           { text: 'Surname', value:"suranme"},
           { text: 'First Name', value:"first_name"},
           { text: 'Gender', value:"gender"},
           { text: 'Civil Status', value:"civil_status"},
           { text: 'Employment', value:"employment"},
           { text: 'Address', value:"address"},
           { text: 'Remarks'},
           { text: '--', value:"--", align: 'right'}
         ],
         filterText: "All"
     }),
     beforeCreate: function () {
       if (!this.$session.exists()) {
         this.$router.push('/')
       }
     },
     created() {
        if (this.$session.exists()) {
           this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
           this.setLoggedIn(true) 
           this.getItems()
           this.getBirthdays()
           this.filterText = "All"
         }
     },
     computed:{
        windowClass() {
         if(this.$store.state.responsive){
           return "va-reponsive"
         } else {
           return "va-not-reponsive"
         }
       },
       FILTERDATA() {
         //['All', 'Adults', 'Senior Citizen', 'SK Members', 'Employed', 'Unemployed']
         switch(this.filterText) {
             case 'Adults':
               return this.Adults
             case 'Senior Citizen':
               return this.Seniors
             case 'SK Members':
               return this.SKs
             case 'Employed':
               return this.Employed
             case 'Unemployed':
               return this.Unemployed  
               default:
                 return this.items
         }
       },
       Adults() {
         var filters = this.items.reduce((res,item)=>{
             if("Adults" == item.category) {
               res.push(item)
             }
             return res
         }, [])
         return filters
       },
       Seniors() {
         var filters = this.items.reduce((res,item)=>{
             if("yes" == item.senior) {
               res.push(item)
             }
             return res
         }, [])
         return filters
       },
       SKs() {
         var filters = this.items.reduce((res,item)=>{
             if("SK" == item.category) {
               res.push(item)
             }
             return res
         }, [])
         return filters
       },
       Employed() {
         var filters = this.items.reduce((res,item)=>{
             if("Employed" == item.employment) {
               res.push(item)
             }
             return res
         }, [])
         return filters
       },
       Unemployed() {
         var filters = this.items.reduce((res,item)=>{
             if("Unemployed" == item.employment) {
               res.push(item)
             }
             return res
         }, [])
         console.log(filters)
         return filters
       },
     },
     methods: {
       ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
       formEv(){
         this.form = false
       },
       isToday(item){
        if(this.$moment(item.birth_date).format("MMM, DD") == this.$moment().format("MMM, DD")) {
          return true
        } else {
          return false
        }
       },
       remarks(data){
           if(data.senior == 'yes') {
             return "Senior Citizen"
           }
           if(data.student == 'yes') {
             return "Student"
           }
           if(data.pwd == 'yes') {
             return "PWD"
           }
 
       },
       getItems() {
         this.$http.get("data/list").then(response => {
                     response.data.items != null?this.items = response.data.items:this.items=[]
                 }).catch(e => {
                  console.log(e.data)
              })
       },
       getBirthdays() {
         this.$http.get("data/birthdays").then(response => {
                     response.data.item != null?this.birthdays = response.data.item:this.birthdays=[]
                 }).catch(e => {
                  console.log(e.data)
              })
       }
   }
 } 
 </script>